var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal no-print",
      attrs: { id: "not-key-earning-deposit", role: "dialog" }
    },
    [
      _c(
        "div",
        { staticClass: "card modal-card", attrs: { role: "document" } },
        [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "modal-content", attrs: { role: "document" } },
            [
              _c("p", [
                _vm.formattedRange
                  ? _c("span", [
                      _c("span", { staticClass: "starts-on date" }, [
                        _vm._v(_vm._s(_vm.formattedRange.formattedStart))
                      ]),
                      _vm._v("\nto\n"),
                      _c("span", { staticClass: "ends-on date" }, [
                        _vm._v(_vm._s(_vm.formattedRange.formattedEnd))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(
                  " will not earn upfront Keys. Keys can only be earned if this week is reserved by a member. To earn upfront Keys, withdraw the week and add a week that is:"
                ),
                _vm._m(1)
              ]),
              _c("div", { staticClass: "buttons text-right" }, [
                _c(
                  "a",
                  { staticClass: "button white", on: { click: _vm.remove } },
                  [_vm._v("Remove")]
                ),
                _vm._v(" "),
                _c("a", { staticClass: "button", on: { click: _vm.close } }, [
                  _vm._v("Add Anyway")
                ])
              ])
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "modal-header" }, [
      _c("h4", [_vm._v("Non-Key-earning week added")]),
      _c("div", { staticClass: "close", attrs: { "aria-label": "close" } }, [
        _c("div", { staticClass: "x" })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("ul", [
      _c("li", [_vm._v("90+ days out")]),
      _c("li", [_vm._v("a Super Peak, Peak, or Non-Peak week")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }