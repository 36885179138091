<template lang="pug">
.cell.twelve.medium-four
  .availability-keys(:class="{ 'option-disabled': !loggedIn }")
    h5.no-bottom-margin Availability Keys
    p.text-center {{ min_keys }} - {{ max_keys }}
    div.slider-container
      div
        #keys-slider-range(ref="slider")
    .input.check_boxes(:class="{ 'option-disabled': !loggedIn }")
      span.checkbox
        input(type="checkbox"
            v-model="keyless"
            :disabled="!loggedIn" id="keyless")
        label(for="keyless") 
          | Keyless Stays
          sup.availability-popup ?
      span.checkbox
        input(type="checkbox"
            v-model="marked_down" 
            :disabled="!loggedIn"
            id="marked_down")
        label(for="marked_down") 
          | On Sale
</template>

<script>
import { sync } from 'vuex-pathify'

export default {
  data () {
    return {
      unsubscribe: null,
      $slider: null
    }
  },
  computed: {
    ...sync('browseHomes', {
      marked_down: 'filters@marked_down',
      keyless: 'filters@keyless',
      min_keys: 'filters@min_keys',
      max_keys: 'filters@max_keys',
      loggedIn: 'loggedIn'
    })
  },
  mounted () {
    this.$slider = $(this.$refs.slider)
    this.$slider.slider({
      range: true,
      min: 1,
      max: 15,
      values: [this.min_keys, this.max_keys],
      slide: (event, ui) => {
        this.min_keys = ui.values[0]
        this.max_keys = ui.values[1]
      },
      disabled: !this.loggedIn
    })
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'browseHomes/CLEAR_ALL_FILTERS' ||
          mutation.type === 'browseHomes/SET_ALL_FILTERS') {
        this.$slider.slider('values', [this.min_keys,this.max_keys])
      }
    })
    if (!this.loggedIn) {
      $(this.$el).tipso({
        background: 'black',
        titleBackground: 'black',
        position: 'left',
        offsetY: -8,
        content: 'Please log in to select availability key filters.'
      })
    }
  },
  beforeDestroy () {
    this.$slider.slider('destroy')
    if (this.unsubscribe) {
      this.unsubscribe()
    }
  }
}
</script>
