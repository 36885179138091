<template lang='pug'>
  .vue-template-wrapper.grid.edge
    .cell.twelve.medium-eight
      DepositCalendar(@dateSelected='dateSelected'
                      :calendarStart='calendarStart'
                      :selectedDate='selectedDate'
                      :existingDeposits='existingDeposits'
                      :selectedDeposits='selectedDeposits'
                      :depositRange='range'
                      @previousMonth='gotoPreviousMonth'
                      @nextMonth='gotoNextMonth',
                      :seasonality='seasonality',
                      :keyEarningStartDate='keyEarningStartDate'
                      v-bind:selectedRange.sync="selectedRange"
                      )

      StayPicker(:optionUrl='optionUrl'
                :selectedDeposits='selectedDeposits'
                :selectedDate='selectedDate'
                :splitWeeks='splitWeeks'
                @dateSelected='dateSelected'
                @stayPicked ="stayPicked"
                @addDeposit='depositAdded')
    .cell.twelve.medium-four
      .deposit-sidebar
        .card.white-card(data-title="Confirm & Submit" data-intro="Confirm and Submit Weeks to Club for Review" data-step="5" )
          .card-content
            h4 Week Selection
            Review(:selectedDeposits='selectedDeposits'
                  :confirmUrl='confirmUrl',
                  :requireConfirmationIds='requireConfirmationIds',
                  :shouldTrackPrompt='shouldTrackPrompt',
                  :redirectPath='redirectPath',
                  :ownerCanNyop='ownerCanNyop',
                  @removeDeposit='depositRemoved',
                  v-if='hasSelectedDeposits')
            div(v-if='!hasSelectedDeposits')
              .text-center
                span.third-font-calendar-plus(style="font-size:3.5rem;opacity:.16")
              br
              br
    NonKeyEarningModal(:selectedDeposit="lastAddedDeposit", @remove="depositRemoved")
</template>

<script>
import DepositCalendar from './calendar/DepositCalendar.vue'
import Review from './confirmation/Review.vue'
import StayPicker from './selection/StayPicker.vue'
import NonKeyEarningModal from './selection/NonKeyEarningModal.vue'
import Ranger from './Ranger.js'

export default {
  components: { DepositCalendar, Review, StayPicker, NonKeyEarningModal },
  props: {
    rangeStart: { type: String, required: true },
    rangeEnd: { type: String, required: true },
    optionUrl: { type: String, required: true },
    confirmUrl: { type: String, required: true },
    existingDeposits: {},
    seasonality: {type: Object, required: true },
    keyEarningStartDate: { type: String, required: true },
    requireConfirmationIds: { type: Boolean, required: true },
    shouldTrackPrompt: { type: Boolean, required: true  },
    redirectPath: { type: String, required: false },
    ownerCanNyop: { type: Boolean, required: false },
    splitWeeks: { type: Boolean, required: true }
  },
  data () {
    let firstMonth = dayjs(this.rangeStart).startOf('month').format('YYYY-MM-DD')
    let startMonth = dayjs(this.rangeStart).add(3, 'months').startOf('month').format('YYYY-MM-DD')
    return {
      selectedDate: '',
      selectedDeposits: [],
      firstMonth: firstMonth,
      calendarStart: startMonth,
      selectedRange: {},
      lastAddedDeposit: null
    }
  },
  computed: {
    hasSelectedDeposits () {
      return this.selectedDeposits.length > 0;
    },
    range () {
      return Ranger.range(this.rangeStart, this.rangeEnd);
    }
  },
  methods: {
    gotoNextMonth () {
      this.gotoMonth(
        dayjs(this.calendarStart).add(1, 'months')
      );
    },
    gotoPreviousMonth () {
      this.gotoMonth(
        dayjs(this.calendarStart).subtract(1, 'months')
      );
    },
    gotoMonth (date) {
      date = date.format('YYYY-MM-DD')
      let nextMonth = dayjs(date).add(1, 'months').format('YYYY-MM-DD')
      if (this.firstMonth <= date && nextMonth <= this.rangeEnd) {
        this.calendarStart = date
      }
    },
    dateSelected (date) {
      this.selectedDate = date;
    },
    depositAdded (deposit) {
      this.selectedDeposits.push(deposit);
      this.lastAddedDeposit = deposit
      this.selectedDate = '';
      this.selectedRange = {}
    },
    stayPicked(range) { this.selectedRange = range },
    depositRemoved (identifier) {
      const position = this.selectedDeposits.map(function(e) {
        return e.identifier;
      }).indexOf(identifier);

      this.selectedDeposits.splice(position, 1);
    }
  }
}
</script>
